<script>
import messages from '@app/messages/emailTemplates';
import APIStore from '@app/services/API/Store';
import EmailTemplate from '@app/components/EmailTemplate.vue';

export default {
  components: { EmailTemplate },
  head: {
    title: 'Emails - Outils Marketing',
  },
  messages,
  data: () => ({ templates: null }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    onUpdate(template) {
      this.templates = this.templates
        .map((v) => (v.id === template.id ? template : v));
    },
  },
  watch: {
    $route(route) {
      if (route.query.onglet == null && this.templates) {
        this.$router.replace({ query: { onglet: this.templates[0].type } });
      }
    },
  },
  created() {
    APIStore.getEmailTemplates(this.authStore.uuid)
      .then((data) => {
        this.templates = data.data;
        this.$router.replace({ query: { onglet: this.templates[0].type } });
      });
  },
};
</script>

<template>
  <div class="comp columns is-relative">
    <div class="column is-4 pt-0 pr-10 is-align-self-flex-start is-sticky">
      <nav class="menu">
        <ul v-if="templates" class="menu-list">
          <li v-for="one in templates" :key="one.id">
            <router-link :to="{query: {onglet: one.type}}" exact-active-class="is-active">
              {{ $options.messages.title[one.type] }}
            </router-link>
          </li>
        </ul>
        <b-skeleton v-else height="240" />
      </nav>

      <hr class="has-background-grey-lighter">

      <div class="content is-small">
        <h2 class="is-size-5">
          Personnalisez les emails envoyés aux apprenants
          <AppFeatureBadge class="ml-3" position="is-right" />
        </h2>
        <p>
          Nous allons envoyer plusieurs emails à vos apprenants en votre nom : <strong>avant, pendant et après leur formation</strong>.
          Vous avez ici la possibilité de les personnaliser.
        </p>
        <p>
          Chaque email est envoyé suite à une action de l'apprenant.
          Vous pouvez indiquer le temps entre l'action réalisée et l'envoi de l'email dans le champ "<strong>Délai avant envoi</strong>".
        </p>
        <p>
          Pour envoyer l'email immédiatement après l'action, indiquez 0.
        </p>
      </div>
    </div>

    <div ref="tpls" class="column is-8">
      <b-skeleton v-if="!templates" height="400" />
      <template v-else>
        <template v-for="one in templates">
          <EmailTemplate
            v-if="$route.query.onglet == one.type"
            :key="one.id"
            :template="one"
            @update="onUpdate"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comp {
  .is-sticky {
    top: 100px;
  }
}
</style>
