export default {
  title: {
    WELCOME: 'Email de bienvenue',
    CERTIFICATE: 'Email de fin de formation',
    QUIT: 'Email de désinscription',
    FIRED: `Email de suppression d\'un apprenant`, // eslint-disable-line
    ABANDONED_1: `1ère relance après un abandon d'achat`, // eslint-disable-line
    ABANDONED_2: `2ème relance après un abandon d'achat`, // eslint-disable-line
    ADDED_BY_USER_WITH_PASSWORD: `Email d'invitation d'un nouvel apprenant`, // eslint-disable-line
    ADDED_BY_USER_WITHOUT_PASSWORD: `Email d'invitation d'un apprenant existant`, // eslint-disable-line
    TRAINING_ITEM_UNBLOCKED: `Email de leçon nouvellement accessible`, // eslint-disable-line
    WEEKLY_RECAP: `Email récapitulatif des formations en cours`, // eslint-disable-line
    TRAINING_BLOCKED: `Email de modification des accès à une formation`, // eslint-disable-line
    TRAINING_BLOCKED_BY_EXPIRATION: `Email d'expiration des accès à une formation`, // eslint-disable-line
    NO_RECENT_CONNECTION_1: `1ère relance après le décrochage d'un apprenant`, // eslint-disable-line
    NO_RECENT_CONNECTION_2: `2ème relance après le décrochage d'un apprenant`, // eslint-disable-line
  },
  text: {
    WELCOME: `
      <p>
        Personnalisez l'email que reçoivent vos apprenants
        <strong>lorsqu'ils s'inscrivent à une de vos formations</strong>.
      </p>
    `,
    CERTIFICATE: `
      <p>
        Personnalisez l'email que reçoivent vos apprenants
        <strong>lorsqu'ils terminent une de vos formations</strong>.
      </p>
      <p>
        C'est l'occasion de les féliciter et de les inviter à récupérer
        le certificat de réussite disponible dans leur espace.
      </p>
    `,
    QUIT: `
      <p>
        Personnalisez l'email que reçoivent vos apprenants
        <strong>lorsqu'ils se désinscrivent</strong>.
      </p>
      <p>
        Profitez-en pour leur demander un feedback, en intégrant par exemple un lien
        vers un questionnaire de satisfaction (Google Form, Typeform, ...).
      </p>
    `,
    FIRED: `
      <p>
        Personnalisez l'email que reçoivent vos apprenants
        <strong>lorsque vous les retirez définitivement d'une formation</strong>.
      </p>
    `,
    ABANDONED_1: `
      <p>
        Ne passez pas à coté d'une vente !
      </p>
      <p>
        Personnalisez le premier email de relance
        que reçoivent les apprenants <strong>qui n'ont pas finalisé leur inscription</strong>
        à une de vos formations ou <strong>qui n'ont pas finalisé l'achat d'un de vos packs</strong>.
      </p>
    `,
    ABANDONED_2: `
      <p>
        C'est votre dernière chance de convaincre un potentiel apprenant !
        Personnalisez le second email de relance
        que reçoivent les apprenants <strong>qui n'ont pas finalisé leur inscription</strong>
        à une de vos formations ou <strong>qui n'ont pas finalisé l'achat d'un de vos packs</strong>.
      </p>
      <p>
        Il est envoyé UNIQUEMENT si la personne n'a toujours pas finalisé son inscription
        malgré la première relance.
      </p>
    `,
    ADDED_BY_USER_WITH_PASSWORD: `
      <p>
        Personnalisez l'email que reçoit un nouvel apprenant
        <strong>lorsque vous lui envoyez une invitation</strong>.
        L'apprenant aura immédiatement accès à la formation grâce aux
        identifiants fournis dans cet email.
      </p>
      <p>
        <strong>La clé {{ mot_de_passe }} est donc obligatoire </strong>
        dans ce template, dans le corps du mail.
      </p>
    `,
    ADDED_BY_USER_WITHOUT_PASSWORD: `
      <p>
        Personnalisez l'email que reçoit un apprenant existant,
        <strong>lorsque vous lui donnez accès à une autre de vos formations</strong>.
      </p>
    `,
    TRAINING_ITEM_UNBLOCKED: `
      <p>
        Personnalisez l'email que reçoit un apprenant lorsqu'il a accès à
        <strong>une leçon qui vient d'être débloquée</strong>.
      </p>
      <p>
        <strong>La clé {{ liste_lecon }} est obligatoire</strong>
        (ne pas mettre le cédille au c).
        Vous n'avez pas la possibilité de modifier le délai avant envoi pour cet email.
      </p>
    `,
    WEEKLY_RECAP: `
      <p>
        Personnalisez l'email récapitulatif que recevra votre apprenant
        <strong>tous les vendredis à 17h30</strong> (uniquement pour ses formations non terminées).
      </p>
      <p>
        <strong>La clé {{ liste_formation }} est obligatoire</strong>.
        Elle contient les informations d'avancement de l'apprenant.
      </p>
    `,
    TRAINING_BLOCKED: `
      <p>
        Personnalisez l'email envoyé à votre apprenant
        <strong>lorsque vous bloquez ou débloquez ses accès à une formation</strong>.
      </p>
      <p>
        <strong>La clé {{ action }} est obligatoire</strong>.
        Elle contient le mot "bloqué" ou le mot "débloqué" en fonction de l'action effectuée.
      </p>
    `,
    TRAINING_BLOCKED_BY_EXPIRATION: `
      <p>
        Personnalisez l'email que recevra votre apprenant
        <strong>lorsque son accès à votre formation arrivera à expiration</strong>.
      </p>
      <p>
        La clé {{ expiration_en_jours }} contient l'information de la durée d'accès à la
        formation avant expiration.
      </p>
    `,
    NO_RECENT_CONNECTION_1: `
      <p>
        Personnalisez l'email que recevra votre apprenant ayant au moins une formation en cours,
        <strong>après un certain temps sans connexion à son espace</strong>.
      </p>
      <p>
        La clé {{ nb_jours_sans_connexion }} exprime en jours la durée
        pendant laquelle l'apprenant ne s'est pas connecté. Configurez-la dans
        le champ "Délai avant envoi".
      </p>
    `,
    NO_RECENT_CONNECTION_2: `
      <p>
        Personnalisez l'email que recevra votre apprenant
        <strong>lorsqu'il ne se sera pas connecté à sa formation depuis un certain temps</strong>.
        Attention, il s'agit de la dernière relance.
      </p>
      <p>
        La clé {{ nb_jours_sans_connexion }} exprime en jours
        la durée pendant laquelle l'apprenant ne s'est pas connecté.
        Configurez-la dans le champ "Délai avant envoi".
      </p>
    `,
  },
};
