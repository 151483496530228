<script>
import cloneDeep from 'lodash/cloneDeep';
import APIStore from '@app/services/API/Store';
import messages from '@app/messages/emailTemplates';
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  messages,
  form: {
    enabled: {
      label: 'Activer cet email',
      switchLabel: 'Activé',
      type: 'switch',
      column: 'is-5',
      inputAttrs: {
        placeholder: '',
      },
    },
    hours_before_sending: {
      label: 'Délai avant envoi (en heures)',
      type: 'number',
      column: 'is-7',
      inputAttrs: {
        min: 0,
        required: true,
      },
    },
    subject: {
      label: 'Objet',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        placeholder: '',
      },
    },
    text: {
      label: 'Corps du mail',
      type: 'textarea',
      column: 'is-12',
      inputAttrs: {
        maxlength: 64000,
        hasCounter: false,
      },
    },
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  validations: {},
  data() {
    return {
      email: { ...this.template },
    };
  },
  created() {
    this.email.subject = this.email.subject ? this.email.subject : this.email.default_subject;
    this.email.text = this.email.text ? this.email.text : this.email.default_text;

    this.form = cloneDeep(this.$options.form);
    this.form.subject.inputAttrs.placeholder = this.email.default_subject;
    this.form.text.inputAttrs.placeholder = this.email.default_text;

    if (this.email.display_hours_in_days) {
      this.form.hours_before_sending.label = 'Délai avant envoi (en jours)';
    }

    if (this.email.no_sending_hours) {
      this.form.hours_before_sending.inputAttrs.disabled = true;
    }
  },
  methods: {
    action() {
      const {
        enabled, hours_before_sending, text, subject,
      } = this.email;

      return APIStore.updateEmailTemplate(this.$store.getters['auth/store'].uuid, this.email.id, {
        enabled, hours_before_sending, text, subject,
      })
        .then(({ data }) => this.$emit('update', data));
    },
  },
};
</script>

<template>
  <form
    :id="`email${email.id}`"
    class="box"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <h3 class="title is-5 mb-3">
      {{ $options.messages.title[email.type] }}
      <AppFeatureBadge class="ml-3" position="is-left" />
    </h3>

    <div class="content">
      <p v-html="$options.messages.text[email.type]" />
    </div>

    <go-pro-wrapper>
      <div class="columns is-multiline">
        <div v-for="(field, k) in form" :key="k" class="column" :class="field.column">
          <BaseField v-model="email[k]" :field="field" />
        </div>
      </div>

      <div v-if="email.available_keys" class="content">
        <p>Liste des variables utilisables :</p>
        <ul>
          <li v-for="one in email.available_keys" :key="one">
            {{ `\{\{ ${one} \}\}` }}
          </li>
        </ul>
      </div>

      <b-field class="has-text-right">
        <b-button type="is-primary" native-type="submit" :loading="isLoading">
          Mettre à jour
        </b-button>
      </b-field>
    </go-pro-wrapper>
  </form>
</template>
